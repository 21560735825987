<template>
  <MyAccountProfileLinksMobile v-if="isMobileOrTablet" :whatsapp-number-link="whatsappNumberLink" />
  <MyAccountProfileLinksDesktop v-else :whatsapp-number-link="whatsappNumberLink" />
</template>

<script setup lang="ts">
const { isMobileOrTablet } = useDevice()
const { store } = useDomainState()
const whatsappNumber = useWhatsappNumer(
  false,
  store.value || undefined,
  useCurrentBranch(useBranchId(), store.value?.branches)
)
const whatsappNumberLink = computed(() => {
  return 'https://wa.me/' + (whatsappNumber || '')
})
</script>
